import { RefObject, useEffect } from 'react';

export function useOutsideClick(
  refs: RefObject<HTMLElement>[],
  handler: (event: MouseEvent) => void
) {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      const { target } = event;

      if (!target || !(target instanceof Node)) {
        return;
      }

      if (refs.some((ref) => ref.current?.contains(target))) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [refs, handler]);
}
