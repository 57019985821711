import Swiper, { Navigation, Pagination } from 'swiper';
import GLightbox from 'glightbox';

Swiper.use([Navigation, Pagination]);

const initSlider = () => {
  new Swiper('.entertainment__slider .swiper', {
    loop: true,
    spaceBetween: 0,

    navigation: {
      nextEl: '.entertainment__next',
      prevEl: '.entertainment__prev',
    },

    pagination: {
      el: '.entertainment__pagination',
      clickable: true,
    },

    // breakpoints: {
    //   768: {
    //     slidesPerView: 2,
    //     spaceBetween: 30,
    //   },
    //   1200: {
    //     slidesPerView: 4,
    //     spaceBetween: 30,
    //   },
    // },
  });
};

const initZoom = () => {
  GLightbox({
    selector: '.entertainment__slide',
    loop: true,
  });
};

const entertainment = {
  init: () => {
    initSlider();
    initZoom();
  },
};

export default entertainment;
