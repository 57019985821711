import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { IProps } from './types';
import './portal.scss';

const portalEl = document.getElementById('portal');

export function Portal({ withBlocking, children }: IProps) {
  useEffect(() => {
    if (!withBlocking || !portalEl?.dataset.count) {
      return;
    }

    document.body.classList.add('body-blocked');
    portalEl.dataset.count = `${+portalEl.dataset.count + 1}`;

    return () => {
      if (!portalEl.dataset.count) {
        return;
      }
      portalEl.dataset.count = `${+portalEl.dataset.count - 1}`;
      if (portalEl.dataset.count === '0') {
        document.body.classList.remove('body-blocked');
      }
    };
  }, [withBlocking]);

  if (!portalEl) {
    return null;
  }

  return createPortal(children, portalEl);
}
