import cn from 'classnames';
import { IProps } from './types';
import styles from './styles.module.scss';

export function Slider({ offsetBy, children, className, ...rest }: IProps) {
  const moduleStyles = cn(styles.module, className);

  return (
    <div className={moduleStyles} {...rest}>
      <div className={styles.wrapper} style={{ left: `${offsetBy * -100}%` }}>
        {children}
      </div>
    </div>
  );
}
