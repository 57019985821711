import { Dispatch, ReactNode } from 'react';

export enum AddReviewActions {
  SetGrade,
  SetName,
  SetEmail,
  SetText,
  TogglePolicy,
  ToggleModal,
  Clear,
}

export type AddReviewActionsType =
  | { type: AddReviewActions.SetGrade; payload: number }
  | { type: AddReviewActions.SetName; payload: string }
  | { type: AddReviewActions.SetEmail; payload: string }
  | { type: AddReviewActions.SetText; payload: string }
  | { type: AddReviewActions.TogglePolicy }
  | { type: AddReviewActions.ToggleModal }
  | { type: AddReviewActions.Clear };

export interface IAddReviewState {
  grade: number;
  name: string;
  email: string;
  text: string;
  policyIsChecked: boolean;
  ui: {
    modalIsOpen: boolean;
  };
}

export type AddReviewContextType = [
  IAddReviewState,
  Dispatch<AddReviewActionsType>
];

export interface IAddReviewProviderProps {
  children: ReactNode;
}
