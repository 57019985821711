import { IStockState, StockActionsType, StockActions } from './types';

export function stockReducer(
  state: IStockState,
  action: StockActionsType,
): IStockState {
  switch (action.type) {
    case StockActions.SetValues:
      return {
        ...state,
        name: action.payload.name,
        text: action.payload.text,
        image: action.payload.image,
      };

    case StockActions.ToggleModal:
      return {
        ...state,
        ui: {
          ...state.ui,
          modalIsOpen: !state.ui.modalIsOpen,
        },
      };

    default:
      throw new Error(`Action ${action} was not recognized`);
  }
}
