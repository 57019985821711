import { CloseButton } from 'common/elements/close-button';
import { useAppContext } from 'features/app';
import { AppActions } from 'features/app/contexts/app-context/types';
import { useCookie } from 'hooks/use-cookie';
import { useState } from 'react';
import styles from './styles.module.scss';

export function Cookie() {
  const [, dispatch] = useAppContext();

  const [isShow, setIsShow] = useState(true);
  const [cookie, updateCookie] = useCookie('cookie_notification');

  if (!isShow || cookie) {
    return null;
  }

  return (
    <div className={styles.module}>
      <CloseButton className={styles.close} onClick={() => setIsShow(false)} />
      <p className={styles.text}>
        <span>
          Наш сайт использует файлы Cookie. Продолжая пользоваться сайтом, вы
          соглашаетесь на использование нами ваших файлов Cookie
        </span>{' '}
        <button
          className={styles.detail}
          onClick={() =>
            dispatch({ type: AppActions.ToggleTextModal, payload: 'cookie' })
          }
        >
          Подронее
        </button>
      </p>
      <button
        className={styles.confirm}
        onClick={() => updateCookie('confirmed', 365)}
      >
        Хорошо, спасибо
      </button>
    </div>
  );
}
