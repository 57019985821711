import { IReviewState, ReviewActionsType, ReviewActions } from './types';

export function reviewReducer(
  state: IReviewState,
  action: ReviewActionsType
): IReviewState {
  switch (action.type) {
    case ReviewActions.SetValues:
      return {
        ...state,
        name: action.payload.name,
        text: action.payload.text,
        grade: action.payload.grade,
      };

    case ReviewActions.ToggleModal:
      return {
        ...state,
        ui: {
          ...state.ui,
          modalIsOpen: !state.ui.modalIsOpen,
        },
      };

    default:
      throw new Error(`Action ${action} was not recognized`);
  }
}
