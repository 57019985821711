import { Dispatch, ReactNode } from 'react';

export enum BookingActions {
  SetArrival,
  SetDeparture,
  SetAdults,
  SetBabies,
  ChangeBaby,
  SetName,
  SetPhone,
  ToggleModal,
  TogglePolicy,
  ToggleArrival,
  ToggleDeparture,
  ToggleBabies,
  Clear,
}

export type BookingActionsType =
  | { type: BookingActions.SetArrival; payload: Date }
  | { type: BookingActions.SetDeparture; payload: Date }
  | { type: BookingActions.SetAdults; payload: number }
  | { type: BookingActions.SetBabies; payload: number }
  | { type: BookingActions.ChangeBaby; payload: { idx: number; age: number } }
  | { type: BookingActions.SetName; payload: string }
  | { type: BookingActions.SetPhone; payload: string }
  | {
      type: BookingActions.ToggleModal;
      payload?: { type: 'booking' | 'calculation'; room?: string };
    }
  | { type: BookingActions.TogglePolicy }
  | { type: BookingActions.ToggleArrival }
  | { type: BookingActions.ToggleDeparture }
  | { type: BookingActions.ToggleBabies }
  | { type: BookingActions.Clear };

export interface IBookingState {
  type?: 'booking' | 'calculation';
  room?: string;
  arrival: Date | null;
  departure: Date | null;
  adults: number;
  babies: number[];
  name: string;
  phone: string;
  policyIsChecked: boolean;

  ui: {
    modalIsOpen: boolean;
    arrivalIsOpen: boolean;
    departureIsOpen: boolean;
    babiesIsOpen: boolean;
  };
}

export type BookingContextType = [IBookingState, Dispatch<BookingActionsType>];

export interface IBookingProviderProps {
  children: ReactNode;
}
