import { questionInitialState } from './state';
import { IQuestionState, QuestionActionsType, QuestionActions } from './types';

export function questionReducer(
  state: IQuestionState,
  action: QuestionActionsType
): IQuestionState {
  switch (action.type) {
    case QuestionActions.SetName:
      return { ...state, name: action.payload };

    case QuestionActions.SetPhone:
      return { ...state, phone: action.payload };

    case QuestionActions.TogglePolicy:
      return {
        ...state,
        policyIsChecked: !state.policyIsChecked,
      };

    case QuestionActions.Clear:
      return questionInitialState;

    default:
      throw new Error(`Action ${action} was not recognized`);
  }
}
