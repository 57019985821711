import { createContext, useContext, useReducer } from 'react';
import { bookingReducer } from './reducer';
import { bookingInitialState } from './state';
import { IBookingProviderProps, BookingContextType } from './types';

const BookingContext = createContext<BookingContextType | null>(null);

export function useBookingContext() {
  const booking = useContext(BookingContext);
  if (booking === null) {
    throw new Error('useBookingContext must be within BookingProvider');
  }
  return booking;
}

export function BookingProvider({ children }: IBookingProviderProps) {
  const value = useReducer(bookingReducer, bookingInitialState);

  return (
    <BookingContext.Provider value={value}>{children}</BookingContext.Provider>
  );
}
