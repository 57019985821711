import { CloseButton } from 'common/elements/close-button';
import { useAppContext } from 'features/app/contexts/app-context';
import { AppActions } from 'features/app/contexts/app-context/types';
import styles from './styles.module.scss';

export function ThanksModalBody() {
  const [state, dispatch] = useAppContext();

  return (
    <div className={styles.module}>
      <CloseButton
        className={styles.close}
        onClick={() => dispatch({ type: AppActions.ToggleThanks })}
      />

      <span className={styles.icon} />

      <h3 className={styles.title}>
        {state.thanksModalType === 'request'
          ? 'Спасибо, заявка отправлена!'
          : 'Спасибо, Ваш отзыв отправлен!'}
      </h3>
      <p className={styles.subtitle}>
        {state.thanksModalType === 'request'
          ? 'Мы свяжемся с вами в ближайшее время!'
          : 'В ближайшее время он будет добавлен на сайт!'}
      </p>
    </div>
  );
}
