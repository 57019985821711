import { Babies } from 'common/elements/babies';
import { Select } from 'common/form/select';
import { useQuizContext } from 'features/quiz/contexts/quiz-context';
import { QuizActions } from 'features/quiz/contexts/quiz-context/types';
import { getAdultOptions, getBabyOptions } from 'utils/booking';
import styles from './styles.module.scss';

export function QuizTwo() {
  const [state, dispatch] = useQuizContext();

  return (
    <div className={styles.module}>
      <Select
        className={styles.adults}
        title="Кол-во взрослых"
        paddingLeft={150}
        options={getAdultOptions()}
        onChange={(event) =>
          dispatch({
            type: QuizActions.SetAdults,
            payload: +event.target.value,
          })
        }
        value={state.adults}
      />

      <Select
        title="Кол-во детей"
        paddingLeft={122}
        options={getBabyOptions()}
        onChange={(event) =>
          dispatch({
            type: QuizActions.SetBabies,
            payload: +event.target.value,
          })
        }
        value={state.babies.length}
      />

      {state.babies.length > 0 && (
        <Babies
          className={styles.babies}
          isOpen={state.ui.babiesIsOpen}
          babies={state.babies}
          onToggle={() => dispatch({ type: QuizActions.ToggleBabies })}
          onChangeBaby={(idx, age) =>
            dispatch({ type: QuizActions.ChangeBaby, payload: { idx, age } })
          }
        />
      )}
    </div>
  );
}
