import { Dispatch, ReactNode } from 'react';

export enum QuestionActions {
  SetName,
  SetPhone,
  TogglePolicy,
  Clear,
}

export type QuestionActionsType =
  | { type: QuestionActions.SetName; payload: string }
  | { type: QuestionActions.SetPhone; payload: string }
  | { type: QuestionActions.TogglePolicy }
  | { type: QuestionActions.Clear };

export interface IQuestionState {
  name: string;
  phone: string;
  policyIsChecked: boolean;
}

export type QuestionContextType = [
  IQuestionState,
  Dispatch<QuestionActionsType>
];

export interface IQuestionProviderProps {
  children: ReactNode;
}
