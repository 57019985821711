import { createContext, useContext, useReducer } from 'react';
import { callbackReducer } from './reducer';
import { callbackInitialState } from './state';
import { ICallbackProviderProps, CallbackContextType } from './types';

const CallbackContext = createContext<CallbackContextType | null>(null);

export function useCallbackContext() {
  const callback = useContext(CallbackContext);
  if (callback === null) {
    throw new Error('useCallbackContext must be within CallbackProvider');
  }
  return callback;
}

export function CallbackProvider({ children }: ICallbackProviderProps) {
  const value = useReducer(callbackReducer, callbackInitialState);

  return (
    <CallbackContext.Provider value={value}>
      {children}
    </CallbackContext.Provider>
  );
}
