import { createContext, useContext, useReducer } from 'react';
import { addReviewReducer } from './reducer';
import { addReviewInitialState } from './state';
import { IAddReviewProviderProps, AddReviewContextType } from './types';

const AddReviewContext = createContext<AddReviewContextType | null>(null);

export function useAddReviewContext() {
  const addReview = useContext(AddReviewContext);
  if (addReview === null) {
    throw new Error('useAddReviewContext must be within AddReviewProvider');
  }
  return addReview;
}

export function AddReviewProvider({ children }: IAddReviewProviderProps) {
  const value = useReducer(addReviewReducer, addReviewInitialState);

  return (
    <AddReviewContext.Provider value={value}>
      {children}
    </AddReviewContext.Provider>
  );
}
