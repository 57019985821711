import { IQuizState } from './types';

export const quizInitialState: IQuizState = {
  step: 1,
  arrival: null,
  departure: null,
  adults: 2,
  babies: [],
  name: '',
  phone: '',
  policyIsChecked: false,

  ui: {
    arrivalIsOpen: false,
    departureIsOpen: false,
    babiesIsOpen: false,
  },
};
