import { IStockState } from './types';

export const stockInitialState: IStockState = {
  name: '',
  text: '',
  image: '',
  ui: {
    modalIsOpen: false,
  },
};
