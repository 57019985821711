import { Dispatch, ReactNode } from 'react';

export enum QuizActions {
  SetStep,
  SetArrival,
  SetDeparture,
  SetAdults,
  SetBabies,
  ChangeBaby,
  SetName,
  SetPhone,
  TogglePolicy,
  ToggleArrival,
  ToggleDeparture,
  ToggleBabies,
  Clear,
}

export type QuizActionsType =
  | { type: QuizActions.SetStep; payload: number }
  | { type: QuizActions.SetArrival; payload: Date }
  | { type: QuizActions.SetDeparture; payload: Date }
  | { type: QuizActions.SetAdults; payload: number }
  | { type: QuizActions.SetBabies; payload: number }
  | { type: QuizActions.ChangeBaby; payload: { idx: number; age: number } }
  | { type: QuizActions.SetName; payload: string }
  | { type: QuizActions.SetPhone; payload: string }
  | { type: QuizActions.TogglePolicy }
  | { type: QuizActions.ToggleArrival }
  | { type: QuizActions.ToggleDeparture }
  | { type: QuizActions.ToggleBabies }
  | { type: QuizActions.Clear };

export interface IQuizState {
  step: number;
  arrival: Date | null;
  departure: Date | null;
  adults: number;
  babies: number[];
  name: string;
  phone: string;
  policyIsChecked: boolean;

  ui: {
    arrivalIsOpen: boolean;
    departureIsOpen: boolean;
    babiesIsOpen: boolean;
  };
}

export type QuizContextType = [IQuizState, Dispatch<QuizActionsType>];

export interface IQuizProviderProps {
  children: ReactNode;
}
