import { createContext, useContext, useReducer } from 'react';
import { appReducer } from './reducer';
import { appInitialState } from './state';
import { IAppProviderProps, AppContextType } from './types';

const AppContext = createContext<AppContextType | null>(null);

export function useAppContext() {
  const app = useContext(AppContext);
  if (app === null) {
    throw new Error('useAppContext must be within AppProvider');
  }
  return app;
}

export function AppProvider({ children }: IAppProviderProps) {
  const value = useReducer(appReducer, appInitialState);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
