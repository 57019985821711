import { useQuizContext } from 'features/quiz/contexts/quiz-context';
import { QuizActions } from 'features/quiz/contexts/quiz-context/types';
import styles from './styles.module.scss';

export function QuizControl() {
  const [state, dispatch] = useQuizContext();

  const handleNext = () => {
    if (!state.arrival) {
      dispatch({ type: QuizActions.ToggleArrival });
      return;
    }

    if (!state.departure) {
      dispatch({ type: QuizActions.ToggleDeparture });
      return;
    }

    dispatch({ type: QuizActions.SetStep, payload: state.step + 1 });
  };

  return (
    <div className={styles.module}>
      {state.step > 1 && state.step < 3 && (
        <button
          className={styles.prev}
          type="button"
          onClick={() =>
            dispatch({ type: QuizActions.SetStep, payload: state.step - 1 })
          }
        />
      )}

      {state.step < 3 && (
        <button className={styles.next} type="button" onClick={handleNext}>
          Далее
        </button>
      )}

      {state.step === 3 && (
        <button className={styles.confirm}>Отправить</button>
      )}
    </div>
  );
}
