import { CloseButton } from 'common/elements/close-button';
import { Policy } from 'common/elements/policy';
import { Input } from 'common/form/input';
import { useAppContext } from 'features/app';
import { AppActions } from 'features/app/contexts/app-context/types';
import { useCallbackContext } from 'features/callback/contexts/callback-context';
import { CallbackActions } from 'features/callback/contexts/callback-context/types';
import { FormEvent } from 'react';
import styles from './styles.module.scss';
import { IProps } from './types';

declare const ym: any;

export function CallbackBody({ onClose }: IProps) {
  const [, appDispatch] = useAppContext();
  const [state, dispatch] = useCallbackContext();

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    ym(91106630, 'reachGoal', 'kick');
    // gtag('event', 'submit', { event_category: 'form' });

    dispatch({ type: CallbackActions.ToggleModal });
    setTimeout(
      () => appDispatch({ type: AppActions.ToggleThanks, payload: 'request' }),
      300
    );

    await fetch('/ajax/_forms.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        form: 'Обратный звонок',
        name: state.name,
        phone: state.phone,
      }),
    });

    dispatch({ type: CallbackActions.Clear });
  }

  return (
    <div className={styles.module}>
      <CloseButton className={styles.close} onClick={onClose} />

      <form className={styles.form} onSubmit={handleSubmit}>
        <h2 className={styles.title}>Обратный звонок</h2>
        <Input
          className={styles.name}
          placeholder="Имя"
          value={state.name}
          onChange={(event) =>
            dispatch({
              type: CallbackActions.SetName,
              payload: event.target.value,
            })
          }
        />

        <Input
          className={styles.phone}
          placeholder="Телефон"
          type="tel"
          pattern="^\+?[0-9()\- ]+"
          required
          value={state.phone}
          onChange={(event) =>
            dispatch({
              type: CallbackActions.SetPhone,
              payload: event.target.value,
            })
          }
        />

        <Policy
          className={styles.policy}
          required
          checked={state.policyIsChecked}
          onToggle={() => dispatch({ type: CallbackActions.TogglePolicy })}
          onClickButton={() =>
            appDispatch({ type: AppActions.ToggleTextModal, payload: 'policy' })
          }
        />

        <button className={styles.button}>Отправить</button>
      </form>
    </div>
  );
}
