import { forwardRef } from 'react';
import cn from 'classnames';
import { IProps } from './types';
import styles from './styles.module.scss';

export const Modal = forwardRef<HTMLDivElement, IProps>(
  ({ onOutsideClick, children, className }, ref) => {
    const moduleStyles = cn(styles.module, className);

    return (
      <div
        ref={ref}
        className={moduleStyles}
        onClick={(event) =>
          event.target === event.currentTarget && onOutsideClick()
        }
      >
        <div className={styles.content}>{children}</div>
      </div>
    );
  }
);
