import { Dispatch, ReactNode } from 'react';

export enum ReviewActions {
  SetValues,
  ToggleModal,
}

export type ReviewActionsType =
  | {
      type: ReviewActions.SetValues;
      payload: { name: string; text: string; grade: string };
    }
  | { type: ReviewActions.ToggleModal };

export interface IReviewState {
  name: string;
  text: string;
  grade: string;
  ui: {
    modalIsOpen: boolean;
  };
}

export type ReviewContextType = [IReviewState, Dispatch<ReviewActionsType>];

export interface IReviewProviderProps {
  children: ReactNode;
}
