import { useRef } from 'react';
import { DatePickerDropdown } from '../date-picker-dropdown';
import { IProps } from './types';
import cn from 'classnames';
import styles from './styles.module.scss';

export function DatePicker({
  name,
  isOpen,
  value,
  disabledDaysBefore,
  disabledDaysAfter,
  onToggle,
  onClickDay,
  className,
  ...rest
}: IProps) {
  const faceRef = useRef<HTMLDivElement>(null);

  const moduleStyles = cn(styles.module, className);

  return (
    <>
      <div className={moduleStyles} ref={faceRef} onClick={onToggle} {...rest}>
        <span className={styles.name}>{name}</span>
        <span className={styles.value}>
          {value && value.toLocaleDateString()}
        </span>
      </div>
      {isOpen && (
        <DatePickerDropdown
          faceRef={faceRef}
          value={value}
          disabledDaysBefore={disabledDaysBefore}
          disabledDaysAfter={disabledDaysAfter}
          onToggle={onToggle}
          onClickDay={onClickDay}
        />
      )}
    </>
  );
}
