import { useLayoutEffect, useRef } from 'react';
import { useOutsideClick } from 'hooks/use-outside-click';
import { IOptions } from './types';
import { calculatePosition } from '../../utils/calculate-position';
import { getElementOffset } from 'utils/browser';

export function useDropdown({
  faceRef,
  onOutsideClick,
  position,
  inViewport,
}: IOptions) {
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOutsideClick([faceRef, dropdownRef], onOutsideClick);

  useLayoutEffect(() => {
    // if (!faceRef.current || !dropdownRef.current) {
    //   throw Error('Ref is not assigned');
    // }

    // const faceEl = faceRef.current;
    // const dropdownEl = dropdownRef.current;

    // const faceBox = faceEl.getBoundingClientRect();
    // const dropdownBox = dropdownEl.getBoundingClientRect();

    // const { top, left } = calculatePosition({
    //   faceBox,
    //   dropdownBox,
    //   position,
    //   inViewport,
    // });

    // dropdownEl.style.top = top;
    // dropdownEl.style.left = left;
    //  VERY GOOD UP

    if (!faceRef.current || !dropdownRef.current) {
      throw Error('Ref is not assigned');
    }

    const faceEl = faceRef.current;
    const dropdownEl = dropdownRef.current;

    // const faceBox = faceEl.getBoundingClientRect();
    // const dropdownBox = dropdownEl.getBoundingClientRect();
    // const { clientHeight, clientWidth } = document.documentElement;

    const { top, left } = getElementOffset(faceEl);

    if (position === 'leftTop') {
      dropdownEl.style.top = top - dropdownEl.offsetHeight + 'px';
      dropdownEl.style.left = left + 'px';
    }
    // else if (position === 'topCenter') {
    //   dropdownEl.style.top = top - dropdownEl.offsetHeight + 'px';
    //   dropdownEl.style.left =
    //     left - dropdownEl.offsetWidth / 2 + faceEl.offsetWidth / 2 + 'px';
    // }
    else if (position === 'rightTop') {
      dropdownEl.style.top = top - dropdownEl.offsetHeight + 'px';
      dropdownEl.style.left =
        left + faceEl.offsetWidth - dropdownEl.offsetWidth + 'px';
    } else if (position === 'leftBottom') {
      dropdownEl.style.top = top + faceEl.offsetHeight + 'px';
      dropdownEl.style.left = left + 'px';
    } else if (position === 'rightBottom') {
      dropdownEl.style.top = top + faceEl.offsetHeight + 'px';
      dropdownEl.style.left =
        left + faceEl.offsetWidth - dropdownEl.offsetWidth + 'px';
    }
    // else if (position === 'bottomCenter') {
    //   dropdownEl.style.top = top + faceEl.offsetHeight + 'px';
    //   dropdownEl.style.left =
    //     left - dropdownEl.offsetWidth / 2 + faceEl.offsetWidth / 2 + 'px';
    // }
  }, []);

  return dropdownRef;
}
