import { Modal } from 'common/layout/modal';
import { Portal } from 'common/other/portal';
import { Transition } from 'common/other/transition';
import { useAddReviewContext } from 'features/add-review/contexts/callback-context';
import { AddReviewActions } from 'features/add-review/contexts/callback-context/types';
import { useEffect, useRef } from 'react';
import { AddReviewBody } from '../add-review-body';

const addReviewButton = document.querySelector('.reviews__add');

export function AddReview() {
  const [state, dispatch] = useAddReviewContext();
  const transitionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function toggle() {
      dispatch({ type: AddReviewActions.ToggleModal });
    }

    addReviewButton?.addEventListener('click', toggle);

    return () => addReviewButton?.removeEventListener('click', toggle);
  }, []);

  return (
    <Transition
      transitionRef={transitionRef}
      isActive={state.ui.modalIsOpen}
      timeout={300}
    >
      <Portal withBlocking>
        <Modal
          onOutsideClick={() =>
            dispatch({ type: AddReviewActions.ToggleModal })
          }
          ref={transitionRef}
        >
          <AddReviewBody
            onClose={() => dispatch({ type: AddReviewActions.ToggleModal })}
          />
        </Modal>
      </Portal>
    </Transition>
  );
}
