import { callbackInitialState } from './state';
import { ICallbackState, CallbackActionsType, CallbackActions } from './types';

export function callbackReducer(
  state: ICallbackState,
  action: CallbackActionsType
): ICallbackState {
  switch (action.type) {
    case CallbackActions.SetName:
      return { ...state, name: action.payload };

    case CallbackActions.SetPhone:
      return { ...state, phone: action.payload };

    case CallbackActions.TogglePolicy:
      return {
        ...state,
        policyIsChecked: !state.policyIsChecked,
      };

    case CallbackActions.ToggleModal:
      return {
        ...state,
        ui: {
          ...state.ui,
          modalIsOpen: !state.ui.modalIsOpen,
        },
      };

    case CallbackActions.Clear:
      return callbackInitialState;

    default:
      throw new Error(`Action ${action} was not recognized`);
  }
}
