import { createContext, useContext, useReducer } from 'react';
import { quizReducer } from './reducer';
import { quizInitialState } from './state';
import { IQuizProviderProps, QuizContextType } from './types';

const QuizContext = createContext<QuizContextType | null>(null);

export function useQuizContext() {
  const quiz = useContext(QuizContext);
  if (quiz === null) {
    throw new Error('useQuizContext must be within QuizProvider');
  }
  return quiz;
}

export function QuizProvider({ children }: IQuizProviderProps) {
  const value = useReducer(quizReducer, quizInitialState);

  return <QuizContext.Provider value={value}>{children}</QuizContext.Provider>;
}
