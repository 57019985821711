import { CloseButton } from 'common/elements/close-button';

import { useReviewContext } from 'features/review/contexts/review-context';
import { ReviewActions } from 'features/review/contexts/review-context/types';
import styles from './styles.module.scss';

export function ReviewBody() {
  const [state, dispatch] = useReviewContext();

  return (
    <div className={styles.module}>
      <CloseButton
        className={styles.close}
        onClick={() => dispatch({ type: ReviewActions.ToggleModal })}
      />

      <span className={styles.name}>{state.name}</span>

      <p className={styles.text}>{state.text}</p>
      <p className={styles.grade}>Оценка {state.grade} из 5</p>
    </div>
  );
}
