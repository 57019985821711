import { Dispatch, ReactNode } from 'react';

export enum StockActions {
  SetValues,
  ToggleModal,
}

export type StockActionsType =
  | {
      type: StockActions.SetValues;
      payload: { name: string; text: string; image: string };
    }
  | { type: StockActions.ToggleModal };

export interface IStockState {
  name: string;
  text: string;
  image: string;
  ui: {
    modalIsOpen: boolean;
  };
}

export type StockContextType = [IStockState, Dispatch<StockActionsType>];

export interface IStockProviderProps {
  children: ReactNode;
}
