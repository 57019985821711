import { createContext, useContext, useReducer } from 'react';
import { reviewReducer } from './reducer';
import { reviewInitialState } from './state';
import { IReviewProviderProps, ReviewContextType } from './types';

const ReviewContext = createContext<ReviewContextType | null>(null);

export function useReviewContext() {
  const review = useContext(ReviewContext);
  if (review === null) {
    throw new Error('useReviewContext must be within ReviewProvider');
  }
  return review;
}

export function ReviewProvider({ children }: IReviewProviderProps) {
  const value = useReducer(reviewReducer, reviewInitialState);

  return (
    <ReviewContext.Provider value={value}>{children}</ReviewContext.Provider>
  );
}
