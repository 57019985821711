import { DatePicker } from 'common/form/date-picker';
import { useQuizContext } from 'features/quiz/contexts/quiz-context';
import { QuizActions } from 'features/quiz/contexts/quiz-context/types';
import styles from './styles.module.scss';

export function QuizOne() {
  const [state, dispatch] = useQuizContext();

  return (
    <div className={styles.module}>
      <DatePicker
        className={styles.arrival}
        name="Дата приезда"
        value={state.arrival}
        isOpen={state.ui.arrivalIsOpen}
        onToggle={() => dispatch({ type: QuizActions.ToggleArrival })}
        onClickDay={(day) => {
          dispatch({ type: QuizActions.SetArrival, payload: day });
          dispatch({ type: QuizActions.ToggleArrival });
        }}
        disabledDaysBefore={new Date()}
      />

      <DatePicker
        name="Дата отъезда"
        value={state.departure}
        isOpen={state.ui.departureIsOpen}
        onToggle={() => dispatch({ type: QuizActions.ToggleDeparture })}
        onClickDay={(day) => {
          dispatch({ type: QuizActions.SetDeparture, payload: day });
          dispatch({ type: QuizActions.ToggleDeparture });
        }}
        disabledDaysBefore={new Date()}
      />
    </div>
  );
}
