import { createContext, useContext, useReducer } from 'react';
import { questionReducer } from './reducer';
import { questionInitialState } from './state';
import { IQuestionProviderProps, QuestionContextType } from './types';

const QuestionContext = createContext<QuestionContextType | null>(null);

export function useQuestionContext() {
  const question = useContext(QuestionContext);
  if (question === null) {
    throw new Error('useQuestionContext must be within QuestionProvider');
  }
  return question;
}

export function QuestionProvider({ children }: IQuestionProviderProps) {
  const value = useReducer(questionReducer, questionInitialState);

  return (
    <QuestionContext.Provider value={value}>
      {children}
    </QuestionContext.Provider>
  );
}
