import GLightbox from 'glightbox';

const initZoom = () => {
  GLightbox({
    selector: '.question__gallery a',
    loop: true,
  });
};

const question = {
  init: () => {
    initZoom();
  },
};

export default question;
