import { Modal } from 'common/layout/modal';
import { Portal } from 'common/other/portal';
import { Transition } from 'common/other/transition';
import { useStockContext } from 'features/stock/contexts/stock-context';
import { StockActions } from 'features/stock/contexts/stock-context/types';
import { useEffect, useRef } from 'react';
import { StockBody } from '../stock-body';

const stockButtons = [
  ...Array.from(
    document.querySelectorAll<HTMLButtonElement>('.stocks__slide div button'),
  ),
  ...Array.from(
    document.querySelectorAll<HTMLButtonElement>('.stocks__item div button'),
  ),
];

export function Stock() {
  const [state, dispatch] = useStockContext();
  const transitionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function toggle(this: HTMLButtonElement) {
      dispatch({ type: StockActions.ToggleModal });
      dispatch({
        type: StockActions.SetValues,
        payload: {
          name: this.dataset.name ?? '',
          text: this.dataset.text ?? '',
          image: this.dataset.image ?? '',
        },
      });
    }

    stockButtons.forEach((button) => button?.addEventListener('click', toggle));

    return () =>
      stockButtons.forEach((button) =>
        button?.removeEventListener('click', toggle),
      );
  }, []);

  return (
    <Transition
      transitionRef={transitionRef}
      isActive={state.ui.modalIsOpen}
      timeout={300}
    >
      <Portal withBlocking>
        <Modal
          onOutsideClick={() => dispatch({ type: StockActions.ToggleModal })}
          ref={transitionRef}
        >
          <StockBody />
        </Modal>
      </Portal>
    </Transition>
  );
}
