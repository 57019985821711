import { IReviewState } from './types';

export const reviewInitialState: IReviewState = {
  name: '',
  text: '',
  grade: '',
  ui: {
    modalIsOpen: false,
  },
};
