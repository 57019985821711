import { Modal } from 'common/layout/modal';
import { Portal } from 'common/other/portal';
import { Transition } from 'common/other/transition';
import { useReviewContext } from 'features/review/contexts/review-context';
import { ReviewActions } from 'features/review/contexts/review-context/types';
import { useEffect, useRef } from 'react';
import { ReviewBody } from '../review-body';

const reviewButtons = Array.from(
  document.querySelectorAll<HTMLButtonElement>('.reviews__button')
);

export function Review() {
  const [state, dispatch] = useReviewContext();
  const transitionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function toggle(this: HTMLButtonElement) {
      dispatch({ type: ReviewActions.ToggleModal });
      dispatch({
        type: ReviewActions.SetValues,
        payload: {
          name: this.dataset.name ?? '',
          text: this.dataset.text ?? '',
          grade: this.dataset.grade ?? '',
        },
      });
    }

    reviewButtons.forEach((button) =>
      button?.addEventListener('click', toggle)
    );

    return () =>
      reviewButtons.forEach((button) =>
        button?.removeEventListener('click', toggle)
      );
  }, []);

  return (
    <Transition
      transitionRef={transitionRef}
      isActive={state.ui.modalIsOpen}
      timeout={300}
    >
      <Portal withBlocking>
        <Modal
          onOutsideClick={() => dispatch({ type: ReviewActions.ToggleModal })}
          ref={transitionRef}
        >
          <ReviewBody />
        </Modal>
      </Portal>
    </Transition>
  );
}
