import { Policy } from 'common/elements/policy';
import { Input } from 'common/form/input';
import { useAppContext } from 'features/app';
import { AppActions } from 'features/app/contexts/app-context/types';
import { useQuizContext } from 'features/quiz/contexts/quiz-context';
import { QuizActions } from 'features/quiz/contexts/quiz-context/types';
import styles from './styles.module.scss';

export function QuizThree() {
  const [, appDispatch] = useAppContext();
  const [state, dispatch] = useQuizContext();

  return (
    <div className={styles.module}>
      <Input
        className={styles.name}
        placeholder="Имя"
        value={state.name}
        onChange={(event) =>
          dispatch({ type: QuizActions.SetName, payload: event.target.value })
        }
      />

      <Input
        className={styles.phone}
        placeholder="Телефон"
        type="tel"
        pattern="^\+?[0-9()\- ]+"
        required
        value={state.phone}
        onChange={(event) =>
          dispatch({ type: QuizActions.SetPhone, payload: event.target.value })
        }
      />

      <Policy
        className={styles.policy}
        checked={state.policyIsChecked}
        required
        onToggle={() => dispatch({ type: QuizActions.TogglePolicy })}
        onClickButton={() =>
          appDispatch({ type: AppActions.ToggleTextModal, payload: 'policy' })
        }
      />
    </div>
  );
}
