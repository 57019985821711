import GLightbox from 'glightbox';

const initZoom = () => {
  GLightbox({
    selector: '.pool__image',
    loop: true,
  });
};

const pool = {
  init: () => {
    initZoom();
  },
};

export default pool;
