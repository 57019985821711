import cn from 'classnames';
import { useRef } from 'react';
import { BabiesDropdown } from '../babies-dropdown';
import styles from './styles.module.scss';
import { IProps } from './types';

export function Babies({
  isOpen,
  babies,
  onToggle,
  onChangeBaby,
  className,
  ...rest
}: IProps) {
  const faceRef = useRef<HTMLDivElement>(null);

  const moduleStyles = cn(styles.module, className);

  return (
    <>
      <div className={moduleStyles} ref={faceRef} onClick={onToggle} {...rest}>
        <span className={styles.name}>Возраст детей</span>
        <span className={styles.value}>
          {babies.filter((_, idx) => idx < 5).join(', ')}
          {babies.length > 5 && ', ...'}
        </span>
      </div>
      {isOpen && (
        <BabiesDropdown
          faceRef={faceRef}
          babies={babies}
          onToggle={onToggle}
          onChangeBaby={onChangeBaby}
        />
      )}
    </>
  );
}
