import { bookingInitialState } from './state';
import { IBookingState, BookingActionsType, BookingActions } from './types';

export function bookingReducer(
  state: IBookingState,
  action: BookingActionsType
): IBookingState {
  switch (action.type) {
    case BookingActions.SetArrival:
      return { ...state, arrival: action.payload };

    case BookingActions.SetDeparture:
      return { ...state, departure: action.payload };

    case BookingActions.SetAdults:
      return { ...state, adults: action.payload };

    case BookingActions.SetBabies:
      return setBabies(state, action);

    case BookingActions.ChangeBaby:
      return {
        ...state,
        babies: [
          ...state.babies.slice(0, action.payload.idx),
          action.payload.age,
          ...state.babies.slice(action.payload.idx + 1),
        ],
      };

    case BookingActions.SetName:
      return { ...state, name: action.payload };

    case BookingActions.SetPhone:
      return { ...state, phone: action.payload };

    case BookingActions.ToggleModal:
      return {
        ...state,
        type: action.payload?.type,
        room: action.payload?.room,
        ui: {
          ...state.ui,
          modalIsOpen: !state.ui.modalIsOpen,
        },
      };

    case BookingActions.TogglePolicy:
      return {
        ...state,
        policyIsChecked: !state.policyIsChecked,
      };

    case BookingActions.ToggleArrival:
      return {
        ...state,
        ui: { ...state.ui, arrivalIsOpen: !state.ui.arrivalIsOpen },
      };

    case BookingActions.ToggleDeparture:
      return {
        ...state,
        ui: { ...state.ui, departureIsOpen: !state.ui.departureIsOpen },
      };

    case BookingActions.ToggleBabies:
      return {
        ...state,
        ui: { ...state.ui, babiesIsOpen: !state.ui.babiesIsOpen },
      };

    case BookingActions.Clear:
      return bookingInitialState;

    default:
      throw new Error(`Action ${action} was not recognized`);
  }
}

function setBabies(
  state: IBookingState,
  action: Extract<BookingActionsType, { type: BookingActions.SetBabies }>
): IBookingState {
  if (action.payload > state.babies.length) {
    return {
      ...state,
      babies: [
        ...state.babies,
        ...Array(action.payload - state.babies.length).fill(0),
      ],
    };
  } else {
    return {
      ...state,
      babies: state.babies.filter((_, idx) => idx < action.payload),
    };
  }
}
