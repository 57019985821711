import cn from 'classnames';
import { IProps } from './types';
import styles from './styles.module.scss';
import { useDropdown } from '../../hooks/use-dropdown';

export function Dropdown({
  faceRef,
  onOutsideClick,
  position = 'leftBottom',
  inViewport = false,
  children,
  className,
  ...rest
}: IProps) {
  const dropdownRef = useDropdown({
    faceRef,
    onOutsideClick,
    position,
    inViewport,
  });

  const moduleStyles = cn(styles.module, className);

  return (
    <div className={moduleStyles} ref={dropdownRef} {...rest}>
      {children}
    </div>
  );
}
