import Swiper, { Navigation, Pagination } from 'swiper';

Swiper.use([Navigation, Pagination]);

const initSlider = () => {
  new Swiper('.stocks__slider .swiper', {
    loop: true,
    spaceBetween: 0,

    navigation: {
      nextEl: '.stocks__next',
      prevEl: '.stocks__prev',
    },

    // pagination: {
    //   el: '.screen__pagination',
    //   clickable: true,
    // },

    // breakpoints: {
    //   768: {
    //     slidesPerView: 2,
    //     spaceBetween: 30,
    //   },
    //   1200: {
    //     slidesPerView: 4,
    //     spaceBetween: 30,
    //   },
    // },
  });
};

const initMore = () => {
  const rooms = document.querySelector('.stocks');
  const button = document.querySelector('.stocks__more');
  button?.addEventListener('click', () =>
    rooms?.classList.add('stocks_opened'),
  );
};

const stocks = {
  init: () => {
    initSlider();
    initMore();
  },
};

export default stocks;
