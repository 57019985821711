import { useState } from 'react';

export function useRerender() {
  const [, setDummy] = useState({});

  function rerender() {
    setDummy({});
  }

  return { rerender };
}
