import { IBookingState } from './types';

export const bookingInitialState: IBookingState = {
  type: 'booking',
  room: '',
  arrival: null,
  departure: null,
  adults: 2,
  babies: [],
  name: '',
  phone: '',
  policyIsChecked: false,

  ui: {
    modalIsOpen: false,
    arrivalIsOpen: false,
    departureIsOpen: false,
    babiesIsOpen: false,
  },
};
