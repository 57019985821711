import cn from 'classnames';
import styles from './styles.module.scss';
import { IProps } from './types';

export function Checkbox({ inverted, className, ...rest }: IProps) {
  const moduleStyles = cn(styles.module, className, {
    [styles.inverted]: inverted,
  });

  return <input className={moduleStyles} type="checkbox" {...rest} />;
}
