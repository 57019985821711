import { quizInitialState } from './state';
import { IQuizState, QuizActionsType, QuizActions } from './types';

export function quizReducer(
  state: IQuizState,
  action: QuizActionsType
): IQuizState {
  switch (action.type) {
    case QuizActions.SetStep:
      return { ...state, step: action.payload };

    case QuizActions.SetArrival:
      return { ...state, arrival: action.payload };

    case QuizActions.SetDeparture:
      return { ...state, departure: action.payload };

    case QuizActions.SetAdults:
      return { ...state, adults: action.payload };

    case QuizActions.SetBabies:
      return setBabies(state, action);

    case QuizActions.ChangeBaby:
      return {
        ...state,
        babies: [
          ...state.babies.slice(0, action.payload.idx),
          action.payload.age,
          ...state.babies.slice(action.payload.idx + 1),
        ],
      };

    case QuizActions.SetName:
      return { ...state, name: action.payload };

    case QuizActions.SetPhone:
      return { ...state, phone: action.payload };

    case QuizActions.TogglePolicy:
      return {
        ...state,
        policyIsChecked: !state.policyIsChecked,
      };

    case QuizActions.ToggleArrival:
      return {
        ...state,
        ui: { ...state.ui, arrivalIsOpen: !state.ui.arrivalIsOpen },
      };

    case QuizActions.ToggleDeparture:
      return {
        ...state,
        ui: { ...state.ui, departureIsOpen: !state.ui.departureIsOpen },
      };

    case QuizActions.ToggleBabies:
      return {
        ...state,
        ui: { ...state.ui, babiesIsOpen: !state.ui.babiesIsOpen },
      };

    case QuizActions.Clear:
      return quizInitialState;

    default:
      throw new Error(`Action ${action} was not recognized`);
  }
}

function setBabies(
  state: IQuizState,
  action: Extract<QuizActionsType, { type: QuizActions.SetBabies }>
): IQuizState {
  if (action.payload > state.babies.length) {
    return {
      ...state,
      babies: [
        ...state.babies,
        ...Array(action.payload - state.babies.length).fill(0),
      ],
    };
  } else {
    return {
      ...state,
      babies: state.babies.filter((_, idx) => idx < action.payload),
    };
  }
}
