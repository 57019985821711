import { ICallbackState } from './types';

export const callbackInitialState: ICallbackState = {
  name: '',
  phone: '',
  policyIsChecked: false,
  ui: {
    modalIsOpen: false,
  },
};
