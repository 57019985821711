import { Modal } from 'common/layout/modal';
import { Portal } from 'common/other/portal';
import { Transition } from 'common/other/transition';
import { useAppContext } from 'features/app/contexts/app-context';
import { AppActions } from 'features/app/contexts/app-context/types';
import { useRef } from 'react';
import { TextModalBody } from '../text-modal-body';

export function TextModal() {
  const [state, dispatch] = useAppContext();
  const transitionRef = useRef<HTMLDivElement>(null);

  return (
    <Transition
      transitionRef={transitionRef}
      isActive={state.ui.textModalIsOpen}
      timeout={300}
    >
      <Portal withBlocking>
        <Modal
          onOutsideClick={() => dispatch({ type: AppActions.ToggleTextModal })}
          ref={transitionRef}
        >
          <TextModalBody />
        </Modal>
      </Portal>
    </Transition>
  );
}
