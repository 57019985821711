import { CloseButton } from 'common/elements/close-button';
import { Policy } from 'common/elements/policy';
import { Input } from 'common/form/input';
import { Radio } from 'common/form/radio';
import { Textarea } from 'common/form/textarea';
import { useAddReviewContext } from 'features/add-review/contexts/callback-context';
import { AddReviewActions } from 'features/add-review/contexts/callback-context/types';
import { useAppContext } from 'features/app';
import { AppActions } from 'features/app/contexts/app-context/types';
import { FormEvent } from 'react';
import styles from './styles.module.scss';
import { IProps } from './types';

export function AddReviewBody({ onClose }: IProps) {
  const [, appDispatch] = useAppContext();
  const [state, dispatch] = useAddReviewContext();

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    dispatch({ type: AddReviewActions.ToggleModal });
    setTimeout(
      () =>
        appDispatch({ type: AppActions.ToggleThanks, payload: 'addReview' }),
      300
    );

    await fetch('/ajax/addReview.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        grade: state.grade,
        name: state.name,
        email: state.email,
        text: state.text,
      }),
    });

    dispatch({ type: AddReviewActions.Clear });
  }

  return (
    <div className={styles.module}>
      <CloseButton className={styles.close} onClick={onClose} />

      <form className={styles.form} onSubmit={handleSubmit}>
        <h2 className={styles.title}>Оставить отзыв</h2>

        <div className={styles.grade}>
          <p className={styles.gradeTitle}>Ваша оценка</p>
          <div className={styles.gradeList}>
            <label>
              <Radio
                name="grade"
                value={1}
                checked={state.grade === 1}
                onChange={(event) =>
                  dispatch({
                    type: AddReviewActions.SetGrade,
                    payload: +event.target.value,
                  })
                }
              />
              <span>1</span>
            </label>

            <label>
              <Radio
                name="grade"
                value={2}
                checked={state.grade === 2}
                onChange={(event) =>
                  dispatch({
                    type: AddReviewActions.SetGrade,
                    payload: +event.target.value,
                  })
                }
              />
              <span>2</span>
            </label>

            <label>
              <Radio
                name="grade"
                value={3}
                checked={state.grade === 3}
                onChange={(event) =>
                  dispatch({
                    type: AddReviewActions.SetGrade,
                    payload: +event.target.value,
                  })
                }
              />
              <span>3</span>
            </label>

            <label>
              <Radio
                name="grade"
                value={4}
                checked={state.grade === 4}
                onChange={(event) =>
                  dispatch({
                    type: AddReviewActions.SetGrade,
                    payload: +event.target.value,
                  })
                }
              />
              <span>4</span>
            </label>

            <label>
              <Radio
                name="grade"
                value={5}
                checked={state.grade === 5}
                onChange={(event) =>
                  dispatch({
                    type: AddReviewActions.SetGrade,
                    payload: +event.target.value,
                  })
                }
              />
              <span>5</span>
            </label>
          </div>
        </div>

        <Input
          className={styles.name}
          placeholder="Имя"
          required
          value={state.name}
          onChange={(event) =>
            dispatch({
              type: AddReviewActions.SetName,
              payload: event.target.value,
            })
          }
        />
        <Input
          className={styles.email}
          placeholder="E-mail"
          required
          type="email"
          value={state.email}
          onChange={(event) =>
            dispatch({
              type: AddReviewActions.SetEmail,
              payload: event.target.value,
            })
          }
        />
        <Textarea
          className={styles.text}
          placeholder="Ваш отзыв"
          required
          value={state.text}
          onChange={(event) =>
            dispatch({
              type: AddReviewActions.SetText,
              payload: event.target.value,
            })
          }
        />

        <Policy
          className={styles.policy}
          required
          checked={state.policyIsChecked}
          onToggle={() => dispatch({ type: AddReviewActions.TogglePolicy })}
          onClickButton={() =>
            appDispatch({ type: AppActions.ToggleTextModal, payload: 'policy' })
          }
        />

        <button className={styles.button}>Отправить</button>
      </form>
    </div>
  );
}
