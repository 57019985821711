import { createContext, useContext, useReducer } from 'react';
import { stockReducer } from './reducer';
import { stockInitialState } from './state';
import { IStockProviderProps, StockContextType } from './types';

const StockContext = createContext<StockContextType | null>(null);

export function useStockContext() {
  const stock = useContext(StockContext);
  if (stock === null) {
    throw new Error('useStockContext must be within StockProvider');
  }
  return stock;
}

export function StockProvider({ children }: IStockProviderProps) {
  const value = useReducer(stockReducer, stockInitialState);

  return (
    <StockContext.Provider value={value}>{children}</StockContext.Provider>
  );
}
