import { IAddReviewState } from './types';

export const addReviewInitialState: IAddReviewState = {
  grade: 5,
  name: '',
  email: '',
  text: '',
  policyIsChecked: false,
  ui: {
    modalIsOpen: false,
  },
};
