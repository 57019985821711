import cn from 'classnames';
import styles from './styles.module.scss';
import { IProps } from './types';

export function Select({
  options,
  title,
  paddingLeft,
  className,
  ...rest
}: IProps) {
  const moduleStyles = cn(styles.module, className, {
    [styles.titled]: title,
  });

  return (
    <div className={moduleStyles}>
      {title && <span className={styles.title}>{title}</span>}
      <select
        className={styles.select}
        {...(paddingLeft ? { style: { paddingLeft: `${paddingLeft}px` } } : {})}
        {...rest}
      >
        {options.map((item) => (
          <option key={item.value} value={item.value}>
            {item.name}
          </option>
        ))}
      </select>
    </div>
  );
}
