import { CloseButton } from 'common/elements/close-button';

import { useStockContext } from 'features/stock/contexts/stock-context';
import { StockActions } from 'features/stock/contexts/stock-context/types';
import styles from './styles.module.scss';

export function StockBody() {
  const [state, dispatch] = useStockContext();

  return (
    <div className={styles.module}>
      <CloseButton
        className={styles.close}
        onClick={() => dispatch({ type: StockActions.ToggleModal })}
      />

      <div className={styles.image}>
        <img src={state.image} alt={state.name} />
      </div>

      <p className={styles.name}>{state.name}</p>

      <p
        className={styles.text}
        dangerouslySetInnerHTML={{ __html: state.text }}
      />
    </div>
  );
}
