import { addReviewInitialState } from './state';
import {
  IAddReviewState,
  AddReviewActionsType,
  AddReviewActions,
} from './types';

export function addReviewReducer(
  state: IAddReviewState,
  action: AddReviewActionsType
): IAddReviewState {
  switch (action.type) {
    case AddReviewActions.SetGrade:
      return { ...state, grade: action.payload };

    case AddReviewActions.SetName:
      return { ...state, name: action.payload };

    case AddReviewActions.SetEmail:
      return { ...state, email: action.payload };

    case AddReviewActions.SetText:
      return { ...state, text: action.payload };

    case AddReviewActions.TogglePolicy:
      return {
        ...state,
        policyIsChecked: !state.policyIsChecked,
      };

    case AddReviewActions.ToggleModal:
      return {
        ...state,
        ui: {
          ...state.ui,
          modalIsOpen: !state.ui.modalIsOpen,
        },
      };

    case AddReviewActions.Clear:
      return addReviewInitialState;

    default:
      throw new Error(`Action ${action} was not recognized`);
  }
}
