import { Dropdown } from 'common/layout/dropdown';
import { Portal } from 'common/other/portal';
import { IProps } from './types';
import styles from './styles.module.scss';
import { Select } from 'common/form/select';
import { getAgeOptions } from 'utils/booking';

export function BabiesDropdown({
  faceRef,
  babies,
  onToggle,
  onChangeBaby,
}: IProps) {
  return (
    <Portal>
      <Dropdown
        faceRef={faceRef}
        onOutsideClick={onToggle}
        position="leftBottom"
      >
        <div className={styles.module}>
          <div className={styles.list}>
            {babies.map((item, idx) => (
              <div className={styles.item} key={idx}>
                <span className={styles.name}>Ребенок {idx + 1}</span>
                <Select
                  className={styles.select}
                  options={getAgeOptions()}
                  value={item}
                  onChange={(event) => onChangeBaby(idx, +event.target.value)}
                />
              </div>
            ))}
          </div>
          <button className={styles.button} onClick={onToggle}>
            <span>Готово</span>
          </button>
        </div>
      </Dropdown>
    </Portal>
  );
}
