import { useLayoutEffect, useRef } from 'react';
import { useRerender } from 'hooks/use-rerender';
import { IProps } from './types';

export function Transition<T extends HTMLElement>({
  isActive,
  timeout,
  children,
  transitionRef,
}: IProps<T>): JSX.Element | null {
  const { rerender } = useRerender();
  const isRendered = useRef(false);

  useLayoutEffect(() => {
    if (!isActive) {
      return;
    }

    transitionRef.current?.classList.add('enter');
    const timer1 = setTimeout(
      () => transitionRef.current?.classList.add('enterActive'),
      0
    );
    const timer2 = setTimeout(() => {
      transitionRef.current?.classList.remove('enter');
      transitionRef.current?.classList.remove('enterActive');
      transitionRef.current?.classList.add('enterDone');
    }, timeout);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, [isActive]);

  useLayoutEffect(() => {
    if (isActive || !isRendered.current) {
      return;
    }

    transitionRef.current?.classList.add('exit');
    const timer1 = setTimeout(
      () => transitionRef.current?.classList.add('exitActive'),
      0
    );
    const timer2 = setTimeout(() => {
      isRendered.current = false;
      rerender();
    }, timeout);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, [isActive]);

  if (isActive) {
    isRendered.current = true;
  }

  if (!isActive && !isRendered.current) {
    return null;
  }

  return children;
}
