import { IAppState } from './types';

export const appInitialState: IAppState = {
  textModalType: 'policy',
  thanksModalType: 'request',
  ui: {
    textModalIsOpen: false,
    thanksIsOpen: false,
  },
};
