import { Dropdown } from 'common/layout/dropdown';
import { Portal } from 'common/other/portal';
import { IProps } from './types';
import { DayPicker } from 'react-day-picker';
import ru from 'date-fns/locale/ru';
import styles from './styles.module.scss';

export function DatePickerDropdown({
  faceRef,
  value,
  disabledDaysBefore,
  disabledDaysAfter,
  onToggle,
  onClickDay,
}: IProps) {
  return (
    <Portal>
      <Dropdown faceRef={faceRef} onOutsideClick={onToggle}>
        <div className={styles.module}>
          <DayPicker
            locale={ru}
            disabled={{ before: disabledDaysBefore, after: disabledDaysAfter }}
            selected={value ?? undefined}
            onDayClick={onClickDay}
          />
        </div>
      </Dropdown>
    </Portal>
  );
}
