import { IAppState, AppActionsType, AppActions } from './types';

export function appReducer(
  state: IAppState,
  action: AppActionsType
): IAppState {
  switch (action.type) {
    case AppActions.ToggleTextModal:
      return {
        ...state,
        textModalType: action.payload,
        ui: {
          ...state.ui,
          textModalIsOpen: !state.ui.textModalIsOpen,
        },
      };

    case AppActions.ToggleThanks:
      return {
        ...state,
        thanksModalType: action.payload,
        ui: {
          ...state.ui,
          thanksIsOpen: !state.ui.thanksIsOpen,
        },
      };

    default:
      throw new Error(`Action ${action} was not recognized`);
  }
}
