import cn from 'classnames';
import { IProps } from './types';
import styles from './styles.module.scss';
import { Checkbox } from 'common/form/checkbox';

export function Policy({
  checked,
  required,
  inverted,
  onToggle,
  onClickButton,
  className,
  ...rest
}: IProps) {
  const moduleStyles = cn(styles.module, className, {
    [styles.inverted]: inverted,
  });

  return (
    <div className={moduleStyles} {...rest}>
      <Checkbox
        className={styles.checkbox}
        checked={checked}
        onChange={onToggle}
        required={required}
        inverted={inverted}
      />
      <p className={styles.block}>
        <span className={styles.text}>Даю согласие на обработку</span>{' '}
        <u className={styles.button} onClick={onClickButton}>
          персональных данных
        </u>
      </p>
    </div>
  );
}
