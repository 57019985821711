import { Modal } from 'common/layout/modal';
import { Portal } from 'common/other/portal';
import { Transition } from 'common/other/transition';
import { useBookingContext } from 'features/booking/contexts/booking-context';
import { BookingActions } from 'features/booking/contexts/booking-context/types';
import { useEffect, useRef } from 'react';
import { BookingBody } from '../booking-body';

const bookingButtons = [
  document.querySelector<HTMLButtonElement>('.hero__booking'),
  document.querySelector<HTMLButtonElement>('.hero__calculation'),
  ...Array.from(document.querySelectorAll('.rooms__booking')),
  ...Array.from(document.querySelectorAll('.rooms__calculation')),
];

export function Booking() {
  const [state, dispatch] = useBookingContext();
  const transitionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function toggle(this: HTMLButtonElement) {
      const modalType =
        this.dataset.type === 'booking' ? 'booking' : 'calculation';

      dispatch({
        type: BookingActions.ToggleModal,
        payload: { type: modalType, room: this.dataset.room ?? undefined },
      });
    }

    bookingButtons.forEach((button) =>
      button?.addEventListener('click', toggle)
    );

    return () =>
      bookingButtons.forEach((button) =>
        button?.removeEventListener('click', toggle)
      );
  }, []);

  return (
    <Transition
      transitionRef={transitionRef}
      isActive={state.ui.modalIsOpen}
      timeout={300}
    >
      <Portal withBlocking>
        <Modal
          onOutsideClick={() => dispatch({ type: BookingActions.ToggleModal })}
          ref={transitionRef}
        >
          <BookingBody
            onClose={() => dispatch({ type: BookingActions.ToggleModal })}
          />
        </Modal>
      </Portal>
    </Transition>
  );
}
