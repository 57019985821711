import { Dispatch, ReactNode } from 'react';

export enum CallbackActions {
  SetName,
  SetPhone,
  TogglePolicy,
  ToggleModal,
  Clear,
}

export type CallbackActionsType =
  | { type: CallbackActions.SetName; payload: string }
  | { type: CallbackActions.SetPhone; payload: string }
  | { type: CallbackActions.TogglePolicy }
  | { type: CallbackActions.ToggleModal }
  | { type: CallbackActions.Clear };

export interface ICallbackState {
  name: string;
  phone: string;
  policyIsChecked: boolean;
  ui: {
    modalIsOpen: boolean;
  };
}

export type CallbackContextType = [
  ICallbackState,
  Dispatch<CallbackActionsType>
];

export interface ICallbackProviderProps {
  children: ReactNode;
}
