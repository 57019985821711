import { Modal } from 'common/layout/modal';
import { Portal } from 'common/other/portal';
import { Transition } from 'common/other/transition';
import { useCallbackContext } from 'features/callback/contexts/callback-context';
import { CallbackActions } from 'features/callback/contexts/callback-context/types';
import { useEffect, useRef } from 'react';
import { CallbackBody } from '../callback-body';

const callbackButtons = [
  document.querySelector('.header__callback'),
  document.querySelector('.footer__callback'),
];

export function Callback() {
  const [state, dispatch] = useCallbackContext();
  const transitionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function toggle() {
      dispatch({ type: CallbackActions.ToggleModal });
    }

    callbackButtons.forEach((button) =>
      button?.addEventListener('click', toggle)
    );

    return () =>
      callbackButtons.forEach((button) =>
        button?.removeEventListener('click', toggle)
      );
  }, []);

  return (
    <Transition
      transitionRef={transitionRef}
      isActive={state.ui.modalIsOpen}
      timeout={300}
    >
      <Portal withBlocking>
        <Modal
          onOutsideClick={() => dispatch({ type: CallbackActions.ToggleModal })}
          ref={transitionRef}
        >
          <CallbackBody
            onClose={() => dispatch({ type: CallbackActions.ToggleModal })}
          />
        </Modal>
      </Portal>
    </Transition>
  );
}
